$(window).load(function(){
	$(document).ready(function () {
		
		/* Confirm the delete
		----------------------------------------------------------------------------------------- */
		$('#confirmDelete').on('show.bs.modal', function(e) {
		    
			$message = $(e.relatedTarget).attr('data-message');
			$(this).find('.modal-body p').text($message);
			
			$title = $(e.relatedTarget).attr('data-title');
			$(this).find('.modal-title').text($title);
			
			// Delete existing form
			$('#TempDeleteForm').remove();
		
			// Get Token and route
			var route = $(e.relatedTarget).attr('data-route');
		    var token = $(e.relatedTarget).attr('data-token');
		    
		    // Create a form element
		    var $form = $('<form/>', {action: route, method: 'post', id: 'TempDeleteForm'});
		    
		    // Add the DELETE hidden input method
		    var $inputMethod = $('<input/>', {type: 'hidden', name: '_method', value: 'delete'});
		    
		    // Add the token hidden input
		    var $inputToken = $('<input/>', {type: 'hidden', name: '_token', value: token});
		    
		    // Append the inputs to the form, hide the form, append the form to the <body>, SUBMIT !
		    $form.append($inputMethod, $inputToken).hide().appendTo('body');
			
			// $(this).find('.modal-footer #confirm').data('form', form);
			
		});
		 
		// Form confirm (yes/ok) handler, submits form
		$('#confirmDelete').find('.modal-footer #confirm').on('click', function(){
		    $('#TempDeleteForm').submit();
		});
		
		// Form cancel, remove form
		$('#confirmDelete').find('.modal-footer #cancel').on('click', function(){
		    $('#TempDeleteForm').remove();
		});
		
		
		/* Flash Message
		----------------------------------------------------------------------------------------- */
		$('div.alert').not('.alert-important').delay(3000).slideUp(300);
		
		
		/* Search on table
		----------------------------------------------------------------------------------------- */
		$("#search-table-input").keyup(function() {
		
		    var value = this.value.toLowerCase().trim();
			
		    $(".search-table").find("tr").each(function(index) {
		        $(this).find("td").each(function () {
		            
		            var id = $(this).text().toLowerCase().trim();
		            var not_found = (id.indexOf(value) == -1);
		            $(this).closest('tr').toggle(!not_found);
		            
		            return not_found;
		        
		        });
		    });
		
		});
		
		/* Search on User Card
		----------------------------------------------------------------------------------------- */
		$("#search-user-input").keyup(function() {
		
		    var value = this.value.toLowerCase().trim();
			
		    $(".search-user").find(".card").each(function(index) {
		        $(this).find(".search-user-content").each(function () {
		            
		            var id = $(this).text().toLowerCase().trim();
		            var not_found = (id.indexOf(value) == -1);
		            $(this).closest('.card').toggle(!not_found);
		            
		            return not_found;
		        });
		    });
		});
		
		
		/* Search on Media
		----------------------------------------------------------------------------------------- */
		$("#search-media-input").keyup(function() {
		
		    var value = this.value.toLowerCase().trim();
			
		    $(".search-media").find(".photo").each(function(index) {
		        $(this).find(".search-media-content").each(function () {
		            
		            var id = $(this).text().toLowerCase().trim();
		            var not_found = (id.indexOf(value) == -1);
		            $(this).closest('.photo').toggle(!not_found);
		            
		            return not_found;
		        });
		    });
		});
		
		
		
		/* Projects list (Map or list)
		----------------------------------------------------------------------------------------- */
		$("#view-map").click(function(event) {
			
			event.preventDefault();
			$("#view-list").removeClass('active');
			$(this).addClass('active');
				
			$('#map-projects').slideDown();
			$('#panel-projects').slideUp();
			$('#projects-search').hide();
		});
		
		$("#view-list").click(function(event) {
			
			event.preventDefault();
			$("#view-map").removeClass('active');
			$(this).addClass('active');
		
			$('#map-projects').slideUp();
			$('#panel-projects').slideDown();
			$('#projects-search').show();
		});


	});
});